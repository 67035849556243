<template>
  <v-container fluid>
    <v-row dense v-if="true">
      <v-col cols="6" md="3" class="mb-3">
        <v-select
          :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26]"
          v-model="weeks"
          label="Weeks"
          hint="How many weeks will the league run (including playoffs)?"
          persistent-hint
          color="color3"
          item-color="color3" dense
          :disabled="disabled"
        ></v-select>
      </v-col>
      <v-col cols="6" md="3">
        <v-select
          :items="Array.from({length: 99}, (_, i) => i + 1)"
          v-model="courts"
          label="Courts"
          hint="How many courts"
          persistent-hint
          color="color3"
          item-color="color3"
          dense
          :disabled="disabled"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-combobox
          v-model="slots"
          label="Time Slots"
          chips multiple small-chips dense
          @focus="timeSlotClick"
          :disabled="disabled"
        ></v-combobox>
        <time-slot-dialog
          ref="timeSlotDialog"
          @update="(val) => {this.slots = val}"
          :timeSlots="slots"
          :disabled="disabled"
        ></time-slot-dialog>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :items="['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ]"
          v-model="dows"
          label="Days of the week"
          hint="What days will this league run?"
          persistent-hint
          color="color3"
          item-color="color3"
          chips multiple small-chips
          :disabled="disabled"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <date-time-picker
          :datetime.sync="tournament.dtRosterCutoff"
          label="Roster Change Cutoff"
          key="rcc"
          :disabled="disabled"
          type="date"
        ></date-time-picker>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TimeSlotDialog from '@/components/Tournament/RoundSettings/TimeSlotDialog.vue'
import LeagueSettings from '@/classes/LeagueSettings'
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'

export default {
  props: ['disabled', 'tournament'],
  data () {
    return {
      weeks: null,
      courts: null,
      slots: [],
      dows: [],
      loading: false,
      settings: null
    }
  },
  computed: {
    dto () {
      return {
        weeks: this.weeks,
        courts: this.courts,
        slots: this.slots,
        dows: this.dows
      }
    },
    categories () {
      return this.settings && this.settings.courtNames.map(m => `Court ${m}`)
    },
    courtSlots () {
      return this.tournament && this.settings && this.settings.getAllSlots(this.tournament.rounds)
    },
    rounds () {
      return this.tournament && this.tournament.rounds
    }
  },
  methods: {
    timeSlotClick () {
      this.$refs.timeSlotDialog.open()
    },
    init () {
      const s = this.tournament.jProps && this.tournament.jProps.leagueSettings
      if (s) {
        this.weeks = s.weeks
        this.courts = s.courts
        this.slots = s.slots
        this.dows = s.dows
      }
      if (this.dows.length === 0) this.dows.push(this.tournament.startDate.format('dddd'))
      this.settings = new LeagueSettings(s)
    },
    save () {
      if (!this.tournament.jProps) this.tournament.jProps = {}
      this.tournament.jProps.leagueSettings = this.dto
      this.loading = true
      this.$VBL.tournament.league.generate({
        id: this.tournament.id,
        jsonProps: JSON.stringify(this.tournament.jProps)
      })
        .then(r => {
          console.log('here')
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    dto: function (val) {
      if (!this.tournament.jProps) this.tournament.jProps = {}
      this.tournament.jProps.leagueSettings = this.dto
    },
    disabled: 'init'
  },
  mounted () {
    this.init()
  },
  components: {
    TimeSlotDialog,
    DateTimePicker
  }
}
</script>

<style lang="scss" scoped>

</style>
